import React from 'react';
import { Image, Stack } from 'react-bootstrap';

export default function Header() {
  return (
    <div className="oxycopy-header">
      <Stack gap={3} className="col-md-3 mx-auto oxycopy-stack">
        <Image className="oxy-image" src={'./Images/oxy-blink.png'} />
        <h3>Oxycopy</h3>
      </Stack>
    </div>
  );
}
