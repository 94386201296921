import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import Header from '../Header/Header';
import LoginForm from '../LoginForm/LoginForm';
import Footer from '../Footer/Footer';

export default function CopyLoginPage() {
  return (
    <div className="form-container">
      <Container>
        <Row>
          <Header />
        </Row>
        <Row className="justify-content-center">
          <Col xs={8}>
            <LoginForm />
          </Col>
        </Row>
        <Row>
          <Footer />
        </Row>
      </Container>
    </div>
  );
}
