import React from 'react';
import PropTypes from 'prop-types';
import { MdClose } from 'react-icons/md';

export default function ModalAlert(props) {
  const onHideModal = () => {
    console.log('Hide Modal');
    props.setShowAlert(false);
  };

  return (
    <div
      style={{ display: props.showAlert ? 'flex' : 'none' }}
      className="modal-response-background">
      <div className="modal-response-wrapper">
        <MdClose className="modal-response-close-button" onClick={onHideModal} />
        <div className="modal-response-image">
          <img src="/Images/oxy-blink.png" alt="oxy-blink" />
        </div>
        <div className="modal-response-content">
          <p>{props.message}</p>
        </div>
      </div>
    </div>
  );
}

ModalAlert.propTypes = {
  setShowAlert: PropTypes.func.isRequired,
  showAlert: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired
};
