import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import CopyLoginPage from './Components/CopyLoginPage/CopyLoginPage';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import CopyFormPage from './Components/CopyFormPage/CopyFormPage';
import { createContext, useState } from 'react';

// Creamos un contexto para almacenar la variable volátil
export const MyContext = createContext();

function App() {
  const [loginVar, setLoginVar] = useState('');

  return (
    <Router>
      <MyContext.Provider value={{ loginVar, setLoginVar }}>
        <Routes>
          <Route path="/" element={<CopyLoginPage />} />
          <Route path="/oxy-copy" element={<CopyFormPage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </MyContext.Provider>
    </Router>
  );
}

export default App;
