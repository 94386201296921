// Importación de los paquetes y componentes necesarios para el formulario
import React, { useContext, useState } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import ModalSpinner from '../ModalSpinner/ModalSpinner';
import { useNavigate } from 'react-router-dom';
import ModalAlert from '../ModalAlert/ModalAlert';
import { MyContext } from '../../App';

// Definición del esquema de validación con Yup
const schema = yup.object().shape({
  email: yup
    .string()
    .email('No es un correo válido')
    .required('El correo es requerido')
    .min(2, 'Muy Corta')
    .max(50, 'Muy Larga'),
  code: yup.string().required('El código es requerido')
});

// Componente principal del formulario de inicio de sesión
export default function LoginForm() {
  // Variable volátil para manejar login
  const { loginVar, setLoginVar } = useContext(MyContext);

  console.log(loginVar);

  // Estados para manejar el spinner de carga y las alertas
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState('');

  // Estado para almacenar los valores del formulario
  // const [form, setForm] = useState({});

  // Hook para manejar la navegación del usuario
  const navigate = useNavigate();

  // Lista de usuarios autorizados con sus respectivos códigos
  const usuariosAutorizados = [
    { code: '#$@$', email: 'prueba@gmail.com' },
    { code: '6edRa@@ew_A2A5', email: 'mary.bonilla@equilibrio.agency' },
    { code: 'soHA+Ec1l4r-je', email: 'barbara.gonzalez@equilibrio.agency' },
    { code: 'Hus_U&aSTl?R5z', email: 'engels.perez@equilibrio.agency' },
    { code: 'rE#UVECh0cuzoT', email: 'kimberlyn.gil@equilibrio.agency' },
    { code: 'qoxon7dre+aTr?', email: 'andres.ramirez@equilibrio.agency' },
    { code: 'rE#UVECh0cuzoT', email: 'test@equilibrio.agency' }
  ];

  // Función para verificar usuario
  function checkUser(values) {
    const userEmail = values.email;
    const isAuthorized = usuariosAutorizados.some(
      (usuario) => usuario.email === userEmail && usuario.code === values.code
    );

    if (!isAuthorized) {
      setIsLoading(false);
      setMessage('Usuario o código inválido');
      setShowAlert(true);
      return;
    }
    setLoginVar('login');
    navigate('/oxy-copy'); // redirige al usuario a la vista CopyFormPage
  }

  return (
    <div>
      <ModalSpinner isLoading={isLoading} />
      <ModalAlert showAlert={showAlert} message={message} setShowAlert={setShowAlert} />
      <Formik
        initialValues={{
          email: '',
          code: ''
        }}
        validationSchema={schema}
        onSubmit={checkUser}>
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row>
              <Form.Group controlId="email">
                <Form.Label column="sm">Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Ingrese su correo electrónico"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  isValid={touched.email && !errors.email}
                  className={touched.email && errors.email ? 'is-invalid' : ''}
                />
                {touched.email && errors.email && (
                  <div className="invalid-feedback">{errors.email}</div>
                )}
              </Form.Group>
            </Row>
            <Row>
              <Form.Group controlId="code">
                <Form.Label column="sm">Código</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingrese el código"
                  name="code"
                  value={values.code}
                  onChange={handleChange}
                  isValid={touched.code && !errors.code}
                  className={touched.code && errors.code ? 'is-invalid' : ''}
                />
                {touched.code && errors.code && (
                  <div className="invalid-feedback">{errors.code}</div>
                )}
              </Form.Group>
            </Row>
            <Row>
              <Button
                className="submit-button"
                variant="primary"
                type="submit"
                disabled={
                  errors.code || (values.code.length < 1 && errors.email) || values.email.length < 1
                  // !isCaptchaVerified
                }>
                Verificar Usuario en la IA
              </Button>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
}
