import React from 'react';
import { Image, Stack } from 'react-bootstrap';

export default function Footer() {
  return (
    <div className="oxycopy-footer">
      <Stack gap={3} className="col-md-3 mx-auto oxycopy-stack">
        <Image className="ead-logo" src={'./Images/equilibrio-logo.png'} />
      </Stack>
    </div>
  );
}
