import React from 'react';
import PropTypes from 'prop-types';

export default function ModalSpinner(props) {
  return (
    <div style={{ display: props.isLoading ? 'flex' : 'none' }} className="custom-modal">
      <div className="custom-modal-content">
        <div className="loader" />
        <div className="custom-modal-text">Generating copy with AI</div>
      </div>
    </div>
  );
}

ModalSpinner.propTypes = {
  isLoading: PropTypes.bool.isRequired
};
