// Importación de las librerías necesarias
import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import ModalSpinner from '../ModalSpinner/ModalSpinner';
import ModalResponse from '../ModalResponse/ModalResponse';
import ReCAPTCHA from 'react-google-recaptcha';
import { MyContext } from '../../App';
import { useNavigate } from 'react-router-dom';

// Validación del esquema de entrada del formulario
const schema = yup.object().shape({
  redSocial: yup.string().required('Este campo es requerido'),
  objetivo: yup.string().required('Este campo es requerido'),
  productoServicio: yup
    .string()
    .required('Este campo es requerido')
    .min(2, 'Este campo debe tener al menos 2 caracteres')
    .max(50, 'Este campo debe tener menos de 50 caracteres'),
  publicoObjetivo: yup
    .string()
    .required('Este campo es requerido')
    .min(2, 'Este campo debe tener al menos 2 caracteres')
    .max(50, 'Este campo debe tener menos de 50 caracteres'),
  tono: yup.string().required('Este campo es requerido')
});

// Componente principal del formulario
export default function CopyForm() {
  const navigate = useNavigate();

  // Variable volatil para manejar login
  const { loginVar, setLoginVar } = useContext(MyContext);
  console.log(setLoginVar);

  // Estado para manejar el spinner de carga
  const [isLoading, setIsLoading] = useState(false);

  // Estado para manejar la respuesta de la API
  const [showResponse, setShowResponse] = useState(false);

  // Estado para almacenar la respuesta de la API
  const [response, setResponse] = useState('');

  useEffect(() => {
    if (loginVar !== 'login') {
      navigate('/', { replace: true });
    }
  }, [loginVar, navigate]);

  // Estado para almacenar si se ha verificado el captcha
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  console.log(isCaptchaVerified);

  // Función que maneja el cambio del captcha
  function onChange(value) {
    setIsCaptchaVerified(true);
    console.log('Captcha value:', value);
  }

  // Opciones para la selección de las redes sociales
  const socialMedia = [
    { value: 'Instagram', label: 'Instagram' },
    { value: 'LinkedIn', label: 'LinkedIn' },
    { value: 'Twitter', label: 'Twitter' },
    { value: 'TikTok', label: 'TikTok' },
    { value: 'YouTube', label: 'YouTube' }
  ];

  // Opciones para la selección del objetivo de la publicación
  const objetivo = [
    { value: 'promocionar', label: 'Promocionar un producto o servicio' },
    { value: 'interaccion', label: 'Generar interacción con los seguidores' },
    {
      value: 'educativo',
      label: 'Compartir contenido educativo o informativo'
    }
  ];

  // Opciones para la selección del tono de la publicación
  const tonos = [
    { value: 'serio', label: 'Serio' },
    { value: 'divertido', label: 'Divertido' },
    { value: 'emocional', label: 'Emocional' },
    { value: 'motivacional', label: 'Motivacional' }
  ];

  // Función para manejar el envío del formulario
  async function callOpenIA(values) {
    console.log('form value: ', values);
    // Muestra el spinner
    setIsLoading(true);

    // Verifica si algún campo de entrada está vacío
    try {
      // Realiza la llamada a la API para generar el contenido de Oxycopy
      const response = await fetch('https://apps-oxycopy.azurewebsites.net/generate-oxy-copy', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        // Se envian los datos del formulario como un objeto JSON
        body: JSON.stringify({
          redSocial: values.redSocial,
          objetivo: values.objetivo,
          publico: values.publicoObjetivo,
          producto: values.productoServicio,
          tono: values.tono
        })
      });
      const data = await response.json();
      // setIsLoading es una función que actualiza el estado isLoading a false
      setIsLoading(false);
      console.log(data);

      // Si la respuesta no es 200, lanza un error
      if (response.status !== 200) {
        setShowResponse(false);

        // Auto oculta el dialogo de carga cuando la operación asincrónica ha terminado
        throw data.error || new Error(`Request failed with status ${response.status}`);
      } else {
        setShowResponse(true);
        setResponse(data.result);
      }
    } catch (error) {
      // Maneja el error
      setIsLoading(false);
      console.error(error.message);
      setShowResponse(true);
      setResponse('Error de comunicacion con la IA');
    }
  }

  // El código JSX muestra un formulario que utiliza la biblioteca Formik para validar y manejar los valores de entrada.
  return (
    <div>
      {/* El componente ModalSpinner muestra un spinner mientras se procesa la respuesta de la API */}
      <ModalSpinner isLoading={isLoading} />
      {/* El componente ModalResponse muestra la respuesta de la API */}
      <ModalResponse
        showResponse={showResponse}
        response={response}
        setShowResponse={setShowResponse}
      />
      <Formik
        validationSchema={schema}
        onSubmit={callOpenIA}
        initialValues={{
          redSocial: '',
          objetivo: '',
          productoServicio: '',
          publicoObjetivo: '',
          tono: ''
        }}>
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row>
              <Form.Group controlId="redSocial">
                <Form.Label column="sm">Selecciona una red social</Form.Label>
                <Form.Control
                  as="select"
                  name="redSocial"
                  value={values.redSocial}
                  onChange={handleChange}
                  isValid={touched.objetivo && !errors.redSocial}>
                  <option>-</option>
                  {socialMedia.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback tooltip type="invalid">
                  {errors.redSocial}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group controlId="objetivo">
                <Form.Label column="sm">Selecciona el objetivo</Form.Label>
                <Form.Control
                  as="select"
                  name="objetivo"
                  value={values.objetivo}
                  onChange={handleChange}
                  isValid={touched.objetivo && !errors.objetivo}>
                  <option>-</option>
                  {objetivo.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback tooltip type="invalid">
                  {errors.objetivo}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group controlId="productoServicio">
                <Form.Label column="sm">Producto ó servicio</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Escribe el producto o servicio"
                  name="productoServicio"
                  value={values.productoServicio}
                  onChange={handleChange}
                  isValid={touched.productoServicio && !errors.productoServicio}
                  className={
                    touched.productoServicio && errors.productoServicio ? 'is-invalid' : ''
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {errors.productoServicio}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group controlId="publicoObjetivo">
                <Form.Label column="sm">Público objetivo</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Escribe tu público objetivo"
                  name="publicoObjetivo"
                  value={values.publicoObjetivo}
                  onChange={handleChange}
                  isValid={touched.publicoObjetivo && !errors.publicoObjetivo}
                  className={touched.publicoObjetivo && errors.publicoObjetivo ? 'is-invalid' : ''}
                />
                <Form.Control.Feedback tooltip type="invalid">
                  {errors.publicoObjetivo}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group controlId="tono">
                <Form.Label column="sm">Selecciona el tono del post</Form.Label>
                <Form.Control
                  as="select"
                  name="tono"
                  value={values.tono}
                  onChange={handleChange}
                  isValid={touched.tono && !errors.tono}
                  className={touched.tono && errors.tono ? 'is-invalid' : ''}>
                  <option>-</option>
                  {tonos.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback tooltip type="invalid">
                  {errors.tono}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Button
                className="submit-button"
                variant="primary"
                type="submit"
                disabled={
                  errors.objetivo ||
                  errors.productoServicio ||
                  errors.publicoObjetivo ||
                  errors.redSocial ||
                  errors.tono ||
                  values.objetivo.length < 1 ||
                  values.productoServicio.length < 1 ||
                  values.publicoObjetivo.length < 1 ||
                  values.tono.length < 1 ||
                  values.redSocial.length < 1 ||
                  !isCaptchaVerified
                }>
                Generate copy with AI
              </Button>
            </Row>

            <ReCAPTCHA
              className="captcha"
              sitekey="6LfkfC0lAAAAAFopWQK_a-PA3n-ed1-EVNr95nNj"
              onChange={onChange}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}
