import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import CopyForm from '../CopyForm/CopyForm';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

export default function CopyFormPage() {
  return (
    <div className="form-container">
      <Container>
        <Row>
          <Header></Header>
        </Row>
        <Row className="justify-content-center">
          <Col xs={8}>
            <CopyForm></CopyForm>
          </Col>
        </Row>
        <Row>
          <Footer />
        </Row>
      </Container>
    </div>
  );
}
