import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { MdClose, MdContentCopy, MdOutlineCheck } from 'react-icons/md';

export default function ModalResponse(props) {
  const [isCopied, setIsCopied] = useState(false);

  const onHideModal = () => {
    console.log('Hide Modal');
    props.setShowResponse(false);
  };

  const copyToClipboard = () => {
    const el = document.createElement('textarea');
    el.value = props.response;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setIsCopied(true);
  };

  return (
    <div
      style={{ display: props.showResponse ? 'flex' : 'none' }}
      className="modal-response-background">
      <div className="modal-response-wrapper">
        <MdClose className="modal-response-close-button" onClick={onHideModal} />
        <div className="modal-response-image">
          <img src="/Images/oxy-blink.png" alt="oxy blink" />
        </div>
        <div className="modal-response-content">
          <p>{props.response}</p>
        </div>
        <div className="modal-response-share-buttons">
          <Button
            className="submit-button"
            variant="primary"
            type="submit"
            onClick={copyToClipboard}>
            Copiar al portapapeles <MdContentCopy />
          </Button>
        </div>
        <div
          className="modal-response-is-copied-message"
          style={{ display: isCopied ? 'flex' : 'none' }}>
          Copiado <MdOutlineCheck />
        </div>
      </div>
    </div>
  );
}

ModalResponse.propTypes = {
  response: PropTypes.string.isRequired,
  showResponse: PropTypes.bool.isRequired,
  setShowResponse: PropTypes.func.isRequired
};
